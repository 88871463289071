const binding = { modules: {}, dataActions: {} };

    (binding.modules['iframe'] = {
        c: () => require('@msdyn365-commerce-modules/iframe/dist/lib/modules/iframe/iframe'),
        $type: 'contentModule',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'iframe',
        p: 'iframe',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/iframe/dist/lib/modules/iframe'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['@msdyn365-commerce-modules|iframe|modules|iframe|iframe'] = {
                c: () => require('@msdyn365-commerce-modules/iframe/dist/lib/modules/iframe/iframe.view.js'),
                cn: '@msdyn365-commerce-modules-iframe-iframe'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };